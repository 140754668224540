<template>
    <div>
        <center>
            <h4>Top Up Saldo</h4>
        </center>
        <br>
        <label> Nominal (Rupiah) </label>
        <money class="form-control" v-model="form.nominal" v-bind="money"></money>
        <b v-if="form.low" class="text-danger" style="font-size:12px">Nominal Top Up Minimal adalah Rp 10.000</b>
        <b v-if="form.high" class="text-danger" style="font-size:12px">Nominal Top Up Maksimal adalah Rp 10.000.000</b>
        <br>
        <br>
        <div class="row" v-if="!isLoading">
            <div class="col-md-6">
                <button v-for="(value, index) in payment_channel[0]" v-bind:key="index"
                    class="btn btn-block text-white btn-topup" :style="'background-color:' + value.class" name="method"
                    v-on:click="topup(value.channel_code)" :disabled="form.low || form.high"> {{ value.channel_name }}
                </button>
            </div>
            <div class="col-md-6">
                <button v-for="(value, index) in payment_channel[1]" v-bind:key="index"
                    class="btn btn-block text-white btn-topup" :style="'background-color:' + value.class" name="method"
                    v-on:click="topup(value.channel_code)" :disabled="form.low || form.high"> {{ value.channel_name }}
                </button>
            </div>
            <div v-show="paypalTampil" class="col-md-12" style="margin-top:10px">
                <button class="btn-topup btn btn-block text-white" style="background-color:#3786c5" name="method"
                    v-on:click="topup('paypal')" :disabled="form.low || form.high"> PayPal
                </button>
            </div>
        </div>
        <div v-else>
            <button
                class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block" disabled>
                <div class="spinner-border spinner" role="status"></div>
                Loading
            </button>
        </div>

    </div>
</template>


<script>
    import {
        Money
    } from 'v-money'

    export default {
        components: {
            Money
        },
        data() {
            return {
                form: {
                    nominal: 0,
                    low: true,
                    high: false,
                },
                payment_channel: [],
                money: {
                    thousands: '.',
                    precision: 0,
                },
                isLoading: false,
                paypalTampil:false
            };
        },
        watch: {
            form: {
                handler(val) {
                    if (val.nominal < 10000) {
                        val.high = false
                        val.low = true
                    } else if (val.nominal >= 10000 && val.nominal <= 10000000) {
                        val.low = false
                        val.high = false
                    } else {
                        val.high = true
                        val.low = false
                    }
                },
                deep: true
            },
        },
        beforeDestroy() {
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);

            this.fetchPaymentChannel()
        },
        methods: {
            async fetchPaymentChannel() {
                try {
                    let payment_channel = await this.$store.dispatch('payment_channel')
                    this.payment_channel = this.chunkArray(payment_channel.data.data.payment_channel, 2)
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
            },
            async topup(payment_channel) {
                this.isLoading = true
                //CHECK
                if (this.form.nominal < 10000) {
                    this.form.high = false
                    this.form.low = true
                } else if (this.form.nominal >= 10000 && this.form.nominal <= 10000000) {
                    this.form.low = false
                    this.form.high = false
                } else {
                    this.form.high = true
                    this.form.low = false
                }

                try {
                    let topup = await this.$store.dispatch('payment_topup', {
                        nominal: parseInt(this.form.nominal),
                        payment_channel: payment_channel
                    })
                    window.location.replace(topup.data.data.transaction.checkout_url)
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.isLoading = false
                }
            },
            chunkArray(arr, n) {
                let chunkLength = Math.max(arr.length / n, 1);
                let chunks = [];
                for (let i = 0; i < n; i++) {
                    if (chunkLength * (i + 1) <= arr.length) chunks.push(arr.slice(chunkLength * i, chunkLength * (i +
                        1)));
                }
                return chunks;
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            addStr(str, index, stringToAdd) {
                return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
            }
        },
    };
</script>