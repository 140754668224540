<template>
    <div class="contents">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main">
                        <h4 class="text-capitalize breadcrumb-title">Tagihan</h4>
                    </div>
                    <div v-if="isExpired()">
                        <div class="alert alert-danger" role="alert">
                            Akun Anda Telah Expired !
                        </div>
                        <br>
                    </div>
                    
                </div>
            </div>
            <div v-if="!isLoading" class="row">
                <div class="col-xxl-12 col-sm-12">
                      <div class="modal fade new-member" id="tambah" role="dialog" aria-labelledby="staticBackdropLabel" tabindex="-1"  aria-hidden="true">
                         <div class="modal-dialog">
                            <div class="modal-content  radius-xl">
                                  <div class="modal-header">
                                     <h6 class="modal-title fw-500" id="staticBackdropLabel">Tambah Paket</h6>
                                     <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span data-feather="x"></span>
                                     </button>
                                  </div>
                                  <div class="modal-body">
                                  <div class="tab-wrapper">
                                <div class="atbd-tab tab-horizontal">
                                    <ul class="nav nav-tabs vertical-tabs" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="tab-v-1-tab" data-toggle="tab" href="#tab-v-1"
                                                role="tab" aria-controls="tab-v-1" aria-selected="true">Pengguna</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="tab-v-2-tab" data-toggle="tab" href="#tab-v-2"
                                                role="tab" aria-controls="tab-v-2" aria-selected="false">Ala Carte</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="tab-v-3-tab" data-toggle="tab" href="#tab-v-3"
                                                role="tab" aria-controls="tab-v-3" aria-selected="false">Agensi</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content">
                                        <div class="tab-pane fade show active" id="tab-v-1" role="tabpanel" aria-labelledby="tab-v-1-tab">
                                            <form action="" method="post">
                                                @csrf
                                                <label for="">Nama Paket</label>
                                                <input type="text" class="form-control" name="name" required>
                                                <br>
                                                <label for="">Lisensi</label>
                                                <input type="number" class="form-control" name="lisensi" value="1" readonly required>
                                                <br>
                                                <label for="">Kuota Pesan</label>
                                                <input type="number" class="form-control" name="message_quota" required>
                                                <br>
                                                <label for="">Max Device</label>
                                                <input type="number" class="form-control" name="max_device" required>
                                                <br>
                                                <label for="">Max CS</label>
                                                <input type="number" class="form-control" name="max_cs" required>
                                                <br>
                                                <label for="">Max Campaign</label>
                                                <input type="number" class="form-control" name="max_campaign" required>
                                                <br>
                                                <label for="">Max Order Online</label>
                                                <input type="number" class="form-control" name="max_order_online" required>
                                                <br>
                                                <label for="">Max Product Link Rotator</label>
                                                <input type="number" class="form-control" name="max_link_rotator" required>
                                                <br>
                                                <label for="">Max Chat Widget</label>
                                                <input type="number" class="form-control" name="max_chat_widget" required>
                                                <br>
                                                <label for="">Durasi (hari)</label>
                                                <input type="number" class="form-control" name="duration">
                                                <br>
                                                <label for="">Harga (Rupiah)</label>
                                                <input type="number" class="form-control" name="price" required>
                                                <br>
                                                <input name="hide" class="checkbox" type="checkbox" id="check-1">
                                                <label for="check-1">
                                                <span class="checkbox-text">Sembunyikan</span>
                                                </label>
                                                <br>
                                                <button type="submit" class="btn btn-success btn-block"> Simpan </button>
                                            </form>
                                        </div>
                                        <div class="tab-pane fade" id="tab-v-2" role="tabpanel" aria-labelledby="tab-v-2-tab">
                                        <form action="" method="post">
                                                @csrf
                                                <label for="">Nama Paket</label>
                                                <input type="text" class="form-control" name="name" required>
                                                <br>
                                                <label for="">Lisensi</label>
                                                <input type="number" class="form-control" name="lisensi" value="1" readonly required>
                                                <br>
                                                <label for="">Kuota Pesan</label>
                                                <input type="number" class="form-control" name="message_quota" required>
                                                <br>
                                                <label for="">Max Device</label>
                                                <input type="number" class="form-control" name="max_device" required>
                                                <br>
                                                <label for="">Max CS</label>
                                                <input type="number" class="form-control" name="max_cs" required>
                                                <br>
                                                <label for="">Max Campaign</label>
                                                <input type="number" class="form-control" name="max_campaign" required>
                                                <br>
                                                <label for="">Max Order Online</label>
                                                <input type="number" class="form-control" name="max_order_online" required>
                                                <br>
                                                <label for="">Max Product Link Rotator</label>
                                                <input type="number" class="form-control" name="max_link_rotator" required>
                                                <br>
                                                <label for="">Max Chat Widget</label>
                                                <input type="number" class="form-control" name="max_chat_widget" required>
                                                <br>
                                                <label for="">Harga (Rupiah)</label>
                                                <input type="number" class="form-control" name="price" required>
                                                <br>
                                                <input name="hide" class="checkbox" type="checkbox" id="check-1">
                                                <label for="check-1">
                                                <span class="checkbox-text">Sembunyikan</span>
                                                </label>
                                                <br>
                                                <button type="submit" class="btn btn-success btn-block"> Simpan </button>
                                            </form>
                                        </div>
                                        <div class="tab-pane fade" id="tab-v-3" role="tabpanel" aria-labelledby="tab-v-3-tab">
                                        <form action="" method="post">
                                                @csrf
                                                <label for="">Nama Paket</label>
                                                <input type="text" class="form-control" name="name" required>
                                                <br>
                                                <label for="">Lisensi</label>
                                                <input type="number" class="form-control" name="lisensi" required>
                                                <br>
                                                <label>Allow Paket</label>
                                                <select name="data[]" id="data" class="form-control" multiple required>
                                                    @foreach($package as $p)
                                                    @if($p->lisensi == "1" && $p->duration)
                                                    <option value=""></option>
                                                    @endif
                                                    @endforeach
                                                </select>
                                                <br>
                                                <br>
                                                <label for="">Kuota Pesan</label>
                                                <input type="number" class="form-control" name="message_quota" required>
                                                <br>
                                                <label for="">Max Device</label>
                                                <input type="number" class="form-control" name="max_device" required>
                                                <br>
                                                <label for="">Max CS</label>
                                                <input type="number" class="form-control" name="max_cs" required>
                                                <br>
                                                <label for="">Max Campaign</label>
                                                <input type="number" class="form-control" name="max_campaign" required>
                                                <br>
                                                <label for="">Max Order Online</label>
                                                <input type="number" class="form-control" name="max_order_online" required>
                                                <br>
                                                <label for="">Max Product Link Rotator</label>
                                                <input type="number" class="form-control" name="max_link_rotator" required>
                                                <br>
                                                <label for="">Max Chat Widget</label>
                                                <input type="number" class="form-control" name="max_chat_widget" required>
                                                <br>
                                                <label for="">Max Chat GPT</label>
                                                <input type="number" class="form-control" name="max_chat_gpt" required>
                                                <br>
                                                <label for="">Durasi (hari)</label>
                                                <input type="number" class="form-control" name="duration">
                                                <br>
                                                <label for="">Harga (Rupiah)</label>
                                                <input type="number" class="form-control" name="price" required>
                                                <br>
                                                <input name="hide" class="checkbox" type="checkbox" id="check-1" checked>
                                                <label for="check-1">
                                                <span class="checkbox-text">Sembunyikan</span>
                                                </label>
                                                <br>
                                                <button type="submit" class="btn btn-success btn-block"> Simpan </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                     
                                  </div>
                            </div>
                         </div>
                      </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="card banner-feature banner-feature--3" style="background: #2c99ff">
                                <div class="banner-feature__shape">
                                    <img src="@/assets/img/svg/Group9060.svg" alt="img" class="svg">
                                </div>
                                <div class="d-flex justify-content-center">
                                    <div class="card-body">
                                        <h3 class="banner-feature__heading color-white">Saldo</h3>
                                        <h1 class="banner-feature__heading color-white">{{ formatRupiah(user_balance) }}</h1>
                                       <!-- <p style="margin-top:5px" class="color-white">Saldo Withdrawable : {{ formatRupiah(withdrawableBalance) }}</p>-->
                                        <a data-toggle="modal" v-on:click="toggleModal('topup')"
                                            class="banner-feature__btn btn color-primary btn-md px-20 bg-white radius-xs fs-15 mt-35 mx-1"
                                            type="button" style="display:inline">Top Up</a>
                                            
                                        <a v-on:click="toggleModal('withdraw')" class="banner-feature__btn btn color-primary btn-md px-20 bg-white radius-xs fs-15 mt-35 mx-1"
                                            type="button" style="display:inline">Withdraw</a>

                                        <router-link to="billing/transaction">
                                            <a class="banner-feature__btn btn color-primary btn-md px-20 bg-white radius-xs fs-15 mt-35 mx-1"
                                                type="button" style="display:inline">List Transaksi</a>
                                        </router-link>
                                        <br>
                                        <a v-on:click="toggleModal('sync');sync()" class="banner-feature__btn btn color-primary btn-md px-20 bg-white radius-xs fs-15 mt-35 mx-1"
                                            type="button" style="display:inline">Sinkronisasi dengan Billing V2</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="card banner-feature--12 d-flex" style="background: rgba(250, 139, 12, 0.3);">
                            <div class="d-flex justify-content-center">
                                    <div class="card-body">
                                        <br>
                                        <h2 class="banner-feature__heading" style="margin:5px">Paket Anda Saat Ini</h2>
                                        <p class="banner-feature__para" style="color:black">
                                            == {{ myPackage.name }} ==
                                            <br>
                                            {{ myPackage.pesan }} {{ $t('dashboard.message') }}
                                            <br>
                                            {{ myPackage.device }} {{ $t('dashboard.device') }}
                                            <br>
                                            {{ myPackage.customer_service }} {{ $t('dashboard.customer_service') }}
                                            <br>
                                            {{ myPackage.campaign }} {{ $t('dashboard.campaign') }}
                                            <br>
                                            {{ myPackage.link_rotator }} {{ $t('dashboard.link_rotator') }}
                                            <br>
                                            {{ myPackage.chat_widget }} {{ $t('dashboard.chat_widget') }}
                                            <br>
                                            {{ myPackage.chat_gpt }} Chat GPT
                                            <br>
                                            <br>
                                            Aktif sampai <span class="badge badge-danger text-white">{{ myPackage.expired_at }}</span>
                                        </p>
                                        <div v-if="$store.state.auth.user.level == 'user-agency' && extendURL">
                                            <a :href="extendURL" target="_blank" class="btn btn-dark btn-block">Perpanjang Paket</a>
                                            <br>
                                        </div>

                                        <!-- <div class="d-flex justify-content-sm-start justify-content-center">
                                        <button class="banner-feature__btn btn color-primary btn-md px-20 bg-white radius-xs fs-15" type="button">Learn More</button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
                <div v-for="(value, index) in packageList" v-bind:key="index" class="col-xxl-3 col-lg-4 col-sm-6 mb-30">
                    <div class="card h-100" :class="{blackBorder: value.isMyPackage}">
                        <div class="card-body p-30" style="flex:none">
                            <div v-if="value.isMyPackage">
                                <center>
                                    <b>Paket Saat Ini</b>
                                </center>
                                <br>
                            </div>
                            <div class="pricing d-flex align-items-center">
                                <span
                                    class="pricing__tag_new color-dark order-bg-opacity-dark rounded-pill ">{{ value.name }}</span>
                            </div>
                            <div class="pricing__price rounded">
                                <p class="pricing_value display-3 color-dark d-flex align-items-center text-capitalize fw-600 mb-1">
                                    {{ formatRupiah(value.price) }}
                                </p>
                                <p class="pricing_subtitle mb-0">{{ value.duration }} Hari</p>
                                <p class="pricing_subtitle mb-0">Mengikuti masa aktif paket saat ini</p>
                            </div>
                            <div class="pricing__features">
                                <ul>
                                    <li>
                                        <span class="fa fa-check"></span> {{ value.lisensi }} Lisensi
                                    </li>
                                    <li v-if="value.pesan != '0'">
                                        <span class="fa fa-check"></span> {{ value.pesan }} Pesan
                                    </li>
                                    <li v-if="value.webhook_media_quota">
                                        <span class="fa fa-check"></span> {{ value.webhook_media_quota }} GB
                                    </li>
                                    <li v-if="value.device != '0'">
                                        <span class="fa fa-check"></span> {{ value.device }} Device
                                    </li>
                                    <li v-if="value.customer_service != '0'">
                                        <span class="fa fa-check"></span> {{ value.customer_service }} Customer Service
                                    </li>
                                    <li v-if="value.campaign != '0'">
                                        <span class="fa fa-check"></span> {{ value.campaign }} Campaign
                                    </li>
                                    
                                    <li v-if="value.link_rotator != '0'">
                                        <span class="fa fa-check"></span> {{ value.link_rotator }} Product Link Rotator 
                                    </li>
                                    <li v-if="value.chat_widget != '0'">
                                        <span class="fa fa-check"></span> {{ value.chat_widget }} Chat Widget
                                    </li>
                                    <li v-if="value.chat_gpt != '0'">
                                        <span class="fa fa-check"></span> {{ value.chat_gpt }} Chat GPT
                                    </li>
                                </ul>
                            </div>

                            <a v-if="value.isMyPackage && value.price != 0" class="btn btn-secondary btn-block" :to="'/billing/buy/' + value.id" style="margin-top:5px">Perpanjang</a>
                            <router-link :to="'/billing/buy/' + value.id" v-else-if="!value.isMyPackage && value.price != 0" >
                                <a class="btn btn-success btn-block" style="margin-top:5px" data-id="">Beli</a>
                            </router-link>
                        </div>
                    </div>
                    <!-- End: .card -->
                </div>
            </div>
            <div v-else class="row">
                <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                    <center>
                        <div class="spinner-border spinner" role="status"></div>
                    </center>
                </div>
            </div>
        </div>
        <modal name="withdraw" :height="'auto'" :adaptive="true" :scrollable="true">
            <div style="margin: 20px">
                <!--<Withdraw :withdrawableBalance="withdrawableBalance"></Withdraw>-->
                <Withdraw :withdrawableBalance="user_balance"></Withdraw>
            </div>
        </modal>
        <modal name="topup" :height="'auto'" :adaptive="true" :scrollable="true">
            <div style="margin: 20px">
                <TopUp></TopUp>
            </div>
        </modal>
        <modal name="sync" :height="'auto'" :adaptive="true" :scrollable="true">
            <div style="margin: 20px">
                {{ syncText }}
            </div>
        </modal>
    </div>
</template>

<script>
    import TopUp from "@/components/forms/billing/TopUp.vue";
    import Withdraw from "@/components/forms/billing/Withdraw.vue";
    import moment from 'moment'

    export default {
        components: {
            TopUp,
            Withdraw
        },
        data() {
            return {
                isLoading: true,
                myPackageLoading: true,
                myPackage: {
                    id: "",
                    name: "",
                    pesan: "0",
                    device: "0",
                    customer_service: "0",
                    campaign: "0",
                    order_online: "0",
                    link_rotator: "0",
                    chat_widget: "0",
                    chat_gpt: "0",
                    expired_at: "",
                    webhook_media_quota:0

                },
                withdrawableBalance: 0,
                user_balance: 0,
                packageList: [],
                extendURL: "",
                syncText: "Sinkronisasi dalam proses..."
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
            this.$root.$off("refresh_data", this.fnRefreshData);
        },
        async mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);

            this.fnRefreshData = (param) => {
                if(param == "user") this.fetchUser()
            }
            this.$root.$on("refresh_data", this.fnRefreshData);

            await this.fetchUser()
            await this.fetchPacakge()
            if(this.$store.state.auth.user.level == 'user-agency'){
                await this.fetchAgencySetting()
            }

            this.isLoading = false
        },
        methods: {
            async fetchUser() {
                try {
                    let user_data = await this.$store.dispatch('user_detail')
                    this.user_balance = user_data.data.data.user.balance
                    this.withdrawableBalance = user_data.data.data.user.withdrawable_balance
                    this.fetchMyPacakge(user_data.data.data.package_data)
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/billing")
                }
            },
            async fetchMyPacakge(p){
                this.myPackageLoading = true
                try{
                    this.myPackage.id = p.id
                    this.myPackage.name = p.package_data.name
                    this.myPackage.pesan = p.message_quota == "-1" ? "Unlimited" : p.message_quota
                    this.myPackage.device = p.max_device == "-1" ? "Unlimited" : p.max_device
                    this.myPackage.customer_service = p.max_cs == "-1" ? "Unlimited" : p.max_cs
                    this.myPackage.campaign = p.max_campaign == "-1" ? "Unlimited" : p.max_campaign
                    this.myPackage.order_online = p.max_order_online == "-1" ? "Unlimited" : p.max_order_online
                    this.myPackage.link_rotator = p.max_link_rotator == "-1" ? "Unlimited" : p.max_link_rotator
                    this.myPackage.chat_widget = p.max_chat_widget == "-1" ? "Unlimited" : p.max_chat_widget
                    this.myPackage.chat_gpt = p.max_chat_gpt == "-1" ? "Unlimited" : p.max_chat_gpt
                    this.myPackage.expired_at = moment.unix(p.expired_at / 1000).format('DD MMMM YYYY')
                    this.myPackage.webhook_media_quota = parseInt(p.webhook_media_quota)/1000000
                }catch(err){
                    console.log(err)
                }
                this.myPackageLoading = false
            },
            async fetchPacakge(){
                this.myPackageLoading = true
                try{
                    let packageList = await this.$store.dispatch('package_list')
                    let pkg = packageList.data.data.packages
                    let p = []

                    for(let i = 0; i < pkg.length; i++){
                        if(this.$store.state.auth.user.level == 'user-agency' && pkg[i].is_addon == 0){
                            continue
                        }

                        p.push(pkg[i])
                    }

                    for (let i = 0; i < p.length; i++) {
                        p[i].pesan = p[i].message_quota == "-1" ? "Unlimited" : p[i].message_quota
                        p[i].device = p[i].max_device == "-1" ? "Unlimited" : p[i].max_device
                        p[i].customer_service = p[i].max_cs == "-1" ? "Unlimited" : p[i].max_cs
                        p[i].campaign = p[i].max_campaign == "-1" ? "Unlimited" : p[i].max_campaign
                        p[i].order_online = p[i].max_order_online == "-1" ? "Unlimited" : p[i].max_order_online
                        p[i].link_rotator = p[i].max_link_rotator == "-1" ? "Unlimited" : p[i].max_link_rotator
                        p[i].chat_widget = p[i].max_chat_widget == "-1" ? "Unlimited" : p[i].max_chat_widget
                        p[i].chat_gpt = p[i].max_chat_gpt == "-1" ? "Unlimited" : p[i].max_chat_gpt
                        p[i].webhook_media_quota = parseInt(p[i].webhook_media_quota)/1000000
                        if(p[i].id == this.myPackage.id){
                            p[i].isMyPackage = true
                        }else{
                            p[i].isMyPackage = false 
                        }
                    }

                    this.packageList = p
                }catch(err){
                    console.log(err)
                }
                this.myPackageLoading = false
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            formatRupiah(numb){
                const format = numb.toString().split('').reverse().join('');
                const convert = format.match(/\d{1,3}/g);
                const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('')
                return rupiah
            },
            matchPackage(a, b){
                if(a == b){
                    return true 
                }else{
                    return false
                }
            },
            isExpired(){
                let now = moment.utc().valueOf();
                if(this.$store.state.auth.package.expired_at < now){
                    return true
                }else{
                    return false
                }
            },
            async fetchAgencySetting(){
                let setting = await this.$store.dispatch('agency_setting_user')
                this.extendURL = setting.data.data.setting.extend_url
            },
            async sync(){
                try{
                    await this.$store.dispatch('package_sync', {})
                    this.syncText = "Sinkronisasi berhasil, silakan refresh halaman ini"
                }catch(err){
                    this.syncText = "Sinkronisasi gagal, pastikan email di v2 dan v3 sama atau kontak admin"
                    this.$toast.error(err.response.data.message)
                }
            }
        },
    };
</script>

<style scoped>
    .nav-link{
        cursor: pointer !important;
    }

    .pricing__tag_new{
        font-size: 13px;
        line-height: 1.5384615385;
        font-weight: 500;
        padding: 7px 22px;
        color: black;
    }

    .blackBorder{
        border: 3px solid black;
    }
</style>
